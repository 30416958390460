.chatWrapper {
    & .sectionTitle {
        color: #000;
        text-align: center;
        font-family: 'Source Serif 4', serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    & .subCenterTitle {
        color: #000;
        text-align: center;
        font-family: 'Source Serif 4', serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
        padding: 0;
    }

    & .nameContainer {
        margin-top: 39px;

        & .quantityField {
            & label {
                color: #3C434D;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.16px;
            }

            & select {
                border-radius: 100px;
                border: 1px solid #E6E6E6;
                box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25) inset;
                /* color: #9E9E9E; */
                font-family: 'Source Serif 4', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.18px;
            }
        }

        & .roleRequest {
            text-align: right;

            & a {
                color: #E57552;
                font-family: 'Source Serif 4', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px;
                letter-spacing: 0.18px;
            }
        }

       & .roleRequests {
            color: #E57552;
            font-family: 'Source Serif 4', serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: 0.18px;
        }

        & .rowInput {
            display: flex;
            justify-content: space-between;

            & .inputBox {
                width: calc(50% - 9px);

                & label {
                    color: #3C434D;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.16px;
                }

                & input {
                    font-family: 'Source Serif 4', serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.18px;
                    border-radius: 100px;
                    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25) inset;
                }

                & select {
                    /* color: #9E9E9E; */
                    font-family: 'Source Serif 4', serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.18px;
                    border-radius: 100px;
                    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.25) inset;
                }

                &>div {
                    line-height: 22px;
                }
            }
        }
    }
}

.lastSection {
    margin: 44px 0;

    & .textareaBox {
        & textarea {
            border-radius: 8px;
            background: #FCF6EB;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        }
    }
}

.thoughtBox {
    margin-top: 59px;

    & h2 {
        color: #000;
        text-align: center;
        font-family: 'Source Serif 4', serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        margin: 0;
        padding: 0;
    }

    & .textareaBox {
        margin-top: 31px;
        margin-bottom: 45px;

        & textarea {
            padding: 20px;
            border-radius: 8px;
            background: #FCF6EB;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
        }
    }

    & .bottomButton {
        display: flex;
        flex-direction: column;
        gap: 26px;

        & button {
            border-radius: 100px;

            &:nth-child(1) {
                color: #FFF;
                text-align: center;
                font-family: 'Source Serif 4', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.72px;
            }

            &:nth-child(2) {
                font-family: 'Source Serif 4', serif;
                color: #3C434D;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.72px;
            }
        }
    }
}